<template>
  <div :style="cssVars">
    <v-container>
      <v-row class="text-center">
        <v-flex 12>
          <v-card
            flat
            color="transparent"
          >
            <div class="display-3 mt-5 mb-2">Page not found.</div>
            <div class="grey--text lighten-5">
              The page you are trying to get to never existed in this reality,
              or has migrated to a parallel universe.
            </div>
          </v-card>
        </v-flex>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
  }),
  computed: {
    cssVars: function () {
      return {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        height: `${this.$vuetify.breakpoint.height / 2}px`
      }
    }
  }
}
</script>

<style>
  .paragraph-text {
    font-size: 18px;
  }
</style>
